import React from 'react'
import { Helmet } from 'react-helmet'
import { Footer } from './templates/Footer'
import { Header } from './templates/Header'
import smoothscroll from 'smoothscroll-polyfill'

const Layout = (props) => {
  const { metaTitle, children, metaDescription, smallHeader } = props
  if (typeof window !== `undefined`) {
    smoothscroll.polyfill()
  }
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'fi',
        }}
      >
        {/*<meta charSet="utf-8" />*/}
        {/*Try different google font import*/}
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,400;0,600;0,900;1,400;1,600;1,900&family=Pacifico&family=Roboto:ital@0;1&display=swap"
          rel="preload"
          as="style"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,400;0,600;0,900;1,400;1,600;1,900&family=Pacifico&family=Roboto:ital@0;1&display=swap"
          media="print"
          onload="this.media='all'"
        />
        {/*Try different google font import*/}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content={metaDescription} />
        <meta name="theme-color" content="#8f8f8f" />
        <title>{metaTitle}</title>
      </Helmet>
      <Header smallHeader={smallHeader} />
      <main>
        <div className="main-content">
          {children}
          <Footer />
        </div>
      </main>
    </>
  )
}

export default Layout
