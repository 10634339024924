import React, { useState } from 'react'
import PyryLogo from '../../images/iampedro.png'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { useMediaQuery } from 'react-responsive'
import { graphql, Link, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

const Header = (props) => {
  const { smallHeader } = props

  const data = useStaticQuery(
    graphql`
      query {
        NavImage: file(
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          name: { eq: "pyry_smallheader" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mobileNavImage: file(
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          name: { eq: "pyry_smallheader" }
        ) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  const headerImageSources = [
    data.mobileNavImage.childImageSharp.fluid,
    {
      ...data.NavImage.childImageSharp.fluid,
      media: `(min-width: 479px)`,
    },
  ]
  const [headerClass, setHeaderClass] = useState({
    transition: 'all 200ms ease-in',
  })
  const [navBarOpen, setNavBarOpen] = useState(false)

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const isNarrow = useMediaQuery({ query: '(max-width: 479px)' })

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (currPos.y <= 0 && prevPos.y <= 0) {
        //console.log(currPos.y, prevPos.y)
        let isVisible = currPos.y <= -300
        if (currPos.y === 0 || prevPos.y === 0) {
          isVisible = false
        }
        const shouldBeClass = isVisible ? 'bg' : ''
        setHeaderClass(shouldBeClass)
      }
    },
    [headerClass]
  )

  const getHeaderContent = () => {
    return (
      <>
        <div className="container">
          <div className="header-container">
            <div className="logo-container">
              {/*<PyryLogo />*/}
              <Link to={'/'}>
                <img
                  width="200px"
                  height="200px"
                  src={PyryLogo}
                  alt="iampedro_logo"
                ></img>
              </Link>
            </div>
            <div
              className={`header-nav-container ${
                navBarOpen && 'show-mobile-nav'
              }`}
            >
              <ul className={`nav-items ${navBarOpen && 'container'}`}>
                <li className="nav-item">
                  <Link to="/blog">Blog</Link>
                </li>
                <li className="nav-item">
                  <Link to="/playground">Playground</Link>
                </li>
                <li className="nav-item">
                  <Link to="/foodblog">Food</Link>
                </li>
              </ul>
            </div>
            <div className="nav-toggle">
              {' '}
              <button
                className={`menu ${navBarOpen && 'opened'}`}
                onClick={() => {
                  setNavBarOpen(!navBarOpen)
                }}
                aria-label="Main Menu"
              >
                <svg width="30" height="30" viewBox="0 0 100 100">
                  <path
                    className="line line1"
                    d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                  />
                  <path className="line line2" d="M 20,50 H 80" />
                  <path
                    className="line line3"
                    d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        {navBarOpen && isMobile && (
          <style jsx="true" global="true">
            {`
              .main-content {
                filter: blur(5px);
                -webkit-filter: blur(5px);
              }
            `}
          </style>
        )}
      </>
    )
  }

  return (
    <>
      <header
        className={`header ${headerClass}`}
        id="header"
        style={{
          opacity: '0.95',
        }}
      >
        {smallHeader ? (
          <BackgroundImage
            Tag="section"
            fluid={headerImageSources}
            style={{ backgroundPosition: 'top' }}
          >
            {getHeaderContent()}
          </BackgroundImage>
        ) : (
          getHeaderContent()
        )}
      </header>
      {smallHeader &&
        (isNarrow ? (
          <style jsx="true" global="true">{`
            .main-content {
              padding-top: 48px;
            }
          `}</style>
        ) : (
          <style jsx="true" global="true">
            {`
              .main-content {
                padding-top: 65px;
              }
            `}
          </style>
        ))}
    </>
  )
}

export { Header }
