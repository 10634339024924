import React, { useState } from 'react'
import ScrolltopIcon from '../../../static/svg/scrolltop.svg'

const ScrollTopArrow = () => {
  const [showScroll, setShowScroll] = useState(false)

  const checkScrollTop = () => {
    if (typeof window !== `undefined`) {
      if (!showScroll && window.pageYOffset > 400) {
        setShowScroll(true)
      } else if (showScroll && window.pageYOffset <= 400) {
        setShowScroll(false)
      }
    }
  }

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  if (typeof window !== `undefined`) {
    window.addEventListener('scroll', checkScrollTop)
  }

  return (
    <button
      aria-label="scroll-to-top"
      id="scrolltotop"
      className={showScroll ? 'show-st-button' : undefined}
      type="button"
      onClick={scrollTop}
    >
      <ScrolltopIcon />
    </button>
  )
}

export { ScrollTopArrow }
